import { useItineraryDayStore } from '~/stores/common/ItineraryDayStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useItineraryDayStore();

  // to avoid reset when add query parameter
  if (to.name != from.name) {
    store.reset();
  }
});
